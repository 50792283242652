<template>
	<div class="skeleton-loader">
		<div
			v-for="index in columnCount"
			:key="index"
			class="skeleton-loader__item"
		>
			<div
				class="skeleton-loader__image-placeholder"
				:class="`skeleton-loader__image-placeholder--${imageRatio}`"
			/>
			<div class="skeleton-loader__text" />
			<div class="skeleton-loader__text" />
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		columnCount: {
			type: Number,
			default: 3,
		},
		imageRatio: {
			type: String,
			default: 'original',
		},
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "./shared";

.skeleton-loader {
	display: grid;
	grid-template-columns: repeat(v-bind(columnCount), 1fr);
	gap: 24px;
	width: 100%;
	max-width: var(--content-width);
	margin: 0 auto;

	&__item {
		display: flex;
		flex-direction: column;
	}

	&__image-placeholder {
		position: relative;
		display: flex;
		width: 100%;
		overflow: hidden;
		height: 0;
		transition: height 0.2s ease-in;

		// aspect ratio: 1;
		// 1 * 100
		padding-bottom: 100%;

		&--portrait {
			// aspect ratio: 4 / 5;
			// 5/4 * 100
			padding-bottom: 125%;

		}

		&--landscape {
			// aspect ratio: 16 / 9
			// 9/16 * 100
			padding-bottom: 56.25%;
		}
	}

	&__image-placeholder,
	&__text {
		@include skeleton-loader-animation;

		border-radius: 5px;
	}

	&__text {
		width: 100%;
		height: 24px;
		margin-top: 8px;

		&:nth-child(3) {
			width: calc(100% - 120px);
		}
	}
}

@mixin product-list-mobile {
	.skeleton-loader {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 839px) {
	@include product-list-mobile;
}
</style>
