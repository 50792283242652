<script setup lang="ts">
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getFullProductQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { getLowestPriceVariant } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants';
import {
	ref,
	computed,
	nextTick,
	StyleValue,
} from 'vue';
import {
	EcommerceProduct,
	EcommerceProductSorting,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
	SortingOptions,
	ImageRatioOption,
} from '@zyro-inc/site-modules/types';
import { useRoute } from 'vue-router';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';

const GAP_SIZE = 24;

interface Props {
	blockId: string;
	pageCount: number;
	currentPage: number;
	products: Array<EcommerceProduct> | [];
	productPages?: Record<string, any>;
	blockStyle?: Record<string, string>;
	textColorVars?: Record<string, string>;
	isProductListShown: boolean;
	isLoading?: boolean;
	isEager?: boolean;
	columnCount?: number;
	translations: Record<string, string>;
	productCategoryId?: string;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	ribbonStyle?: Record<string, string>;
	isProductListItemLinkDisabled?: boolean;
	siteId: string;
	variantsQuantity: Array<EcommerceProductVariantQuantity>;
	productSorting: EcommerceProductSorting;
	sorting?: typeof ECOMMERCE_SORTING_VALUES[keyof typeof ECOMMERCE_SORTING_VALUES];
	imageRatio?: ImageRatioOption;
}

const props = withDefaults(defineProps<Props>(), {
	isProductListShown: true,
	columnCount: 3,
	buttonType: 'primary',
	productPages: () => ({}),
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	productCategoryId: '',
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonBorderWidth: 0,
	ribbonStyle: () => ({}),
	sorting: ECOMMERCE_SORTING_VALUES[SortingOptions.DEFAULT],
	productSorting: () => ({
		enabled: false,
	}),
	imageRatio: ImageRatioOption.COVER,
});

const emit = defineEmits([
	'product-click',
	'button-click',
	'page-changed',
	'sort-changed',
]);

const route = useRoute();

const isAnimationActive = ref(false);
const customAttributes = computed(() => ({
	[DATA_ATTRIBUTE_ANIMATION_STATE]: isAnimationActive.value ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null,
}));
const emptyPageMessage = computed(() => props.translations.onlineStoreNoProducts || 'No publicly visible products');

const isCentered = computed(() => props.blockStyle?.textAlign === 'center');
const imageWidth = computed(() => {
	const totalGapsWidth = (props.columnCount - 1) * GAP_SIZE;
	const listWidth = DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH - totalGapsWidth;

	return Math.floor(listWidth / props.columnCount);
});
const gapSize = computed(() => `${GAP_SIZE}px`);
const computedStyles = computed(() => objectToCssVariables(props.textColorVars) as StyleValue);
const computedSelectStyles = computed(() => ({
	color: props.productSorting.textColor || '#000',
}));

const handlePageChange = async (page: number) => {
	// reset animation first so it would be re-triggered on page change
	isAnimationActive.value = false;
	emit('page-changed', page);

	await nextTick();

	isAnimationActive.value = true;

	const block = document.getElementById(props.blockId);
	const blockRect = block?.getBoundingClientRect();
	const isInViewport = blockRect && blockRect.top >= 0 && blockRect.bottom <= window.innerHeight;

	if (!isInViewport) {
		block?.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

const getItemProductPage = (productId: string | number) => Object.values(props.productPages).find((page) => page.productId === productId);

const getItemProductPageTo = (productId: string | number) => {
	if (props.isProductListItemLinkDisabled) {
		return route;
	}

	const productPage = getItemProductPage(productId);

	if (!productPage) {
		return {
			path: '/',
		};
	}

	return {
		path: `/${productPage.slug}`,
	};
};

const getSmallestProductPrice = (product: EcommerceProduct): EcommerceVariantPrice => (
	isProductPriceRangeShown(product) ? getLowestPriceVariant(product).prices[0] : product.variants[0].prices[0]
);

const getProductImage = (product: EcommerceProduct): string | null => {
	if (!isProductPriceRangeShown(product)) {
		return product.thumbnail;
	}

	return getLowestPriceVariant(product).image_url || product.thumbnail;
};
</script>

<template>
	<div
		:id="blockId"
		class="block-product-list-wrapper"
		:style="computedStyles"
	>
		<div
			v-if="productSorting.enabled && productSorting.sortingOptions?.length"
			className="block-product-list__sorting"
		>
			<label
				for="sorting-options"
				:style="computedSelectStyles"
				className="block-product-list__sorting-label"
			>
				{{ translations.sortBy }}:
			</label>
			<select
				id="sorting-options"
				className="block-product-list__sorting-select"
				:value="props.sorting"
				:style="computedSelectStyles"
				@change="emit('sort-changed', $event)"
			>
				<option
					v-for="option in productSorting.sortingOptions"
					:key="option.id"
					:value="option.value"
				>
					{{ option.label }}
				</option>
			</select>
		</div>
		<ListSkeletonLoader
			v-if="isLoading"
			:column-count="columnCount"
			:image-ratio="imageRatio"
		/>
		<div
			v-else-if="isProductListShown"
			ref="productList"
			class="block-product-list"
		>
			<div class="block-product-list__content">
				<RouterLink
					v-for="(product, index) in products"
					:key="`product-${index}-${product.id}`"
					:to="getItemProductPageTo(product.id)"
					class="block-product-list__link"
				>
					<ProductListItem
						v-bind="customAttributes"
						:image="getProductImage(product)"
						:title="product.title"
						:ribbon="product.ribbon_text"
						:price="getSmallestProductPrice(product)"
						:is-centered="isCentered"
						:is-eager="isEager && index === 0"
						:duration="getFormattedBookingDuration(product, translations)"
						:image-width="imageWidth"
						:image-ratio="imageRatio"
						:is-store-quantity-tracked="product.variants[0].manage_inventory"
						:is-price-range-shown="isProductPriceRangeShown(product)"
						:quantity="getFullProductQuantity({
							product,
							variantsQuantity
						})"
						:product-type="product.type.value"
						:translations="translations"
						:is-button-enabled="isButtonEnabled"
						:button-display="buttonDisplay"
						:button-text="buttonText"
						:button-style="buttonStyle"
						:button-type="buttonType"
						:button-border-width="buttonBorderWidth"
						:ribbon-style="ribbonStyle"
						:is-purchasable="product.purchasable ?? true"
						:site-id="siteId"
						@click="$emit('product-click', product)"
						@button-click="$emit('button-click', product)"
					/>
				</RouterLink>
			</div>
			<ZyroPagination
				:current-page="currentPage"
				:page-count="pageCount"
				class="block-product-list__pagination"
				color="var(--body-color)"
				@change-page="handlePageChange($event)"
			/>
		</div>
		<ProductListEmptyState
			v-else
			:text-color-vars="textColorVars"
			:empty-page-message="emptyPageMessage"
		/>
	</div>
</template>

<style lang="scss" scoped>
@mixin product-list-mobile {
	.block-product-list-wrapper {
		padding: var(--m-block-padding);
	}

	.block-product-list {
		&__content {
			grid-template-columns: repeat(2, 1fr);
		}

		&__link {
			width: 100%;
		}
	}
}

.block-product-list-wrapper {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);
	width: 100%;
}

.block-product-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__link {
		width: 100%;
		text-decoration: none;
	}

	&__sorting {
		width: 100%;
		max-width: var(--content-width);
		text-align: right;
		margin: 0 auto;
		padding-bottom: 24px;
	}

	&__sorting-label {
		margin-right: 12px;
		font-size: 14px;
	}

	&__sorting-select {
		padding-bottom: 4px;
		outline: none;
		border: none;
		font-size: 14px;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(v-bind(columnCount), 1fr);
		gap: v-bind(gapSize);
		width: 100%;
		justify-items: center;
		max-width: var(--content-width);
	}

	&__pagination {
		margin-top: 16px;
	}
}

.zyro-mb-preview {
	@include product-list-mobile;
}

@media screen and (max-width: 839px) {
	@include product-list-mobile;
}

</style>
